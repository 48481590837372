import React from 'react';
import SLetter from '../../assets/SLETTER.png';
import ELetter from '../../assets/ELETTER.png';
import ILetter from '../../assets/ILETTER.png';
import PLetter from '../../assets/PLETTER.png';
import OLetter from '../../assets/OLETTER.png';
import RLetter from '../../assets/RLETTER.png';
import TLetter from '../../assets/TLETTER.png';
import ALetter from '../../assets/ALETTER.png';
import LLetter from '../../assets/LLETTER.png';
import PortalGif from '../../assets/AnimatedPortal.gif';
import DiscordIcon from '../../assets/DISCORD.png';
import XIcon from '../../assets/X.png';
import "./styles.css";

const letters = [
  { src: SLetter, alt: 'S', className: 'letter-img' },
  { src: ELetter, alt: 'E', className: 'letter-img' },
  { src: ILetter, alt: 'I', className: 'letter-img' },
  { src: PLetter, alt: 'P', className: 'letter-img' },
  { src: OLetter, alt: 'O', className: 'letter-img' },
  { src: RLetter, alt: 'R', className: 'letter-img' },
  { src: TLetter, alt: 'T', className: 'letter-img' },
  { src: ALetter, alt: 'A', className: 'letter-img' },
  { src: LLetter, alt: 'L', className: 'letter-img' },
  { src: SLetter, alt: 'S', className: 'letter-img' },
];

const SeiPortalsText = () => {
  const text = "The Coin is Emerging...";

  return (
    <div className="bg-black flex flex-col items-center justify-center h-screen">
      <div className="flex justify-center items-center mb-24">
        {letters.map((letter, index) => (
          <img key={index} src={letter.src} alt={letter.alt} className={letter.className}/>
        ))}
      </div>
      <div className="text-white z-30 pixel-font sm:text-3xl md:text-6xl">
        {text.split('').map((letter, index) => (
          <span key={index} className="fade-letter" style={{animationDelay: `${index * 0.3}s`}}>
            {letter === ' ' ? '\u00A0' : letter}
          </span>
        ))}
      </div>
      <img src={PortalGif} alt="Portal" className="portal-img"/>
      <div className="flex justify-center items-center space-x-24">
        {/* Social Media Icons */}
        <a href="https://twitter.com/seiportals">
          <img src={XIcon} alt="Twitter" className="h-32"/>
        </a>
        <a href="https://discord.gg/seiportals">
          <img src={DiscordIcon} alt="Discord" className="h-32"/>
        </a>
      </div>
    </div>
  );
};

export default SeiPortalsText;
