import React from 'react';
import SeiPortalsText from './components/ImageHeader/index'; // Adjust the import path as needed

const App = () => {
  return (
    <div>
      <SeiPortalsText />
    </div>
  );
};

export default App;
